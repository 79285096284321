body {
  font-family: 'Lato';
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.fa-btn {
  margin-right: 6px;
}

.label {
  font-size: 80%;
}

.permission-checkbox {
  margin: 5px 0px;

  label {
    font-size: 100%;
  }
}

.page-header {
  margin: 0px;
}

.page-title {
  font-size: 28px;
  margin: 0px 0px 20px 0px;
}

#pws-logo {
  display: block;
  margin: 40px auto;
}

#opening-sales-header {
  background-color: green;
}

/*#cnt-cutsheet { background-color: blue; }*/

#cnt-controls {
  text-align: right;
}

#cnt-footer {
  background-color: #FF8C00;
}

.bd-red {
  border: 1px solid red;
}

.bd-green {
  border: 1px solid green;
}

.bd-yellow {
  border: 1px solid yellow;
}

.bd-blue {
  border: 1px solid yellow;
}

.bg-red {
  background-color: red;
}

.bg-green {
  background-color: green;
}

.bg-yellow {
  background-color: yellow;
}

.bg-blue {
  background-color: blue;
}

.cs-error {
  color: red;
}

.cs-not-saved {
  background-color: #f39c12;
  border-color: #f39c12;
}

.cs-saved {
  //background-color: green;
}

.cnt-cutsheet {
  padding: 10px;
  position: relative;
}

.opening-summary p {
  margin: 0px;
}

// parts
//#cnt-cats{ float: right; }
//#cnt-master-parts{ float: left; }
.tabbed-header {
  height: 100%;
}

#installer-parts {
  height: 382px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.fab-details {
  font-family: Consolas, monaco, monospace;
}

.parts-list {
  list-style: none;
  padding: 0px;
}

#parts-ordered-grid {
  display: table;
}

.parts-grid-line {
  display: table-row;
}

.parts-grid-cell {
  display: table-cell;
  padding-right: 10px;
  padding-bottom: 2px;
}

.edit-parts-field {
  margin-bottom: 5px;
}

#part-controls {
  margin: 5px 0px;
}

#filter-control {
  position: fixed;
}

#cs-parts-list {
  margin-top: 30px;
}

#ptree {
  width: 500px;
  float: left;
}

.part-color-spacer {
}

.cutsheet-button-bar {
  text-align: right;
  float: right;
}

#cutsheet-name {
  text-align: left;
}

#cnt-csform {
  border: 1px solid green;
  width: 55%;
}

#cnt-csCalcFields {
  border: 1px solid green;
  width: 50%;
  float: right;
}

dl.cs-calc-list dd {
  margin-bottom: 10px;
  border-bottom: 1px dashed white
}

dl.cs-calc-list dt {
  color: #258cd1;
}

#product-name {
  position: absolute;
  top: -70px;
}

.part-category-tree-main {
  border-left: 1px solid #464545;
  border-right: 1px solid #464545;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.padding-top-25 {
  padding-top: 25px !important;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-bottom-5 {
  margin-bottom: 5px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.part-category-tree-main {
  border-left: 1px solid #464545;
  border-right: 1px solid #464545;
}

.part-category-tree.list-group {
  margin: 0px;
  padding: 0px;

  .toggle-icon {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
  }

  .toggle-icon:after {
    /* symbol for "opening" panels */
    font-family: 'Glyphicons Halflings'; /* essential for enabling glyphicon */
    content: "\e114"; /* adjust as needed, taken from bootstrap.css */
    float: right; /* adjust as needed */
    color: grey; /* adjust as needed */
  }

  .toggle-icon.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\e080"; /* adjust as needed, taken from bootstrap.css */
  }

  li {
    margin: 0px;
    padding: 0px;
    cursor: pointer;
    border-radius: 0px;
    border: none;
    border-top: 1px solid #464545;

    div {
      cursor: pointer;
      padding: 10px 10px 10px 20px; // Level 1
    }

    li {
      div {
        padding: 10px 10px 10px 40px; // Level 2
      }

      li {
        div {
          padding: 10px 10px 10px 60px; // Level 3
        }

        li {
          div {
            padding: 10px 10px 10px 80px; // Level 4
          }

          li {
            div {
              padding: 10px 10px 10px 100px; // Level 5
            }

            li {
              div {
                padding: 10px 10px 10px 120px; // Level 6
              }

              li {
                div {
                  padding: 10px 10px 10px 140px; // Level 7
                }

                li {
                  div {
                    padding: 10px 10px 10px 160px; // Level 8
                  }

                  li {
                    div {
                      padding: 10px 10px 10px 180px; // Level 9
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  .product-node {
    background-color: lighten(#303030, 10%)
  }

  .product-node.active {
    background-color: #375a7f
  }

  .list-group-item.active {
    background-color: #375a7f !important;
  }
  
  .btn-composition {
    padding: 2px 5px;
    font-size: 12px;
  }
}

.box-title {
  margin: 20px 0px;
}

.checkbox-sm {
  width: 20px !important;
  height: 20px !important;
}

.opening-nav-item {
  text-align: center;
}

.opening-nav-item.openingSelected {
  background-color: #375a7f;
  color: white;
}

.opening-nav-link {
  color: white;
  text-decoration: none;
}

.opening-alerts.glyphicon {
  font-size: 12px;
  text-decoration: none;
}

.opening-nav-item.openingSelected a {
  color: white;
  text-decoration: none;
}

.dz-default.dz-message span {
  color: #375a7f;
}

.clickable {
  cursor: pointer;
}

.panel-heading span {
  margin-top: -20px;
  font-size: 15px;
}

.col-xs-offset-right-12 {
  margin-right: 100%;
}

.col-xs-offset-right-11 {
  margin-right: 91.66666667%;
}

.col-xs-offset-right-10 {
  margin-right: 83.33333333%;
}

.col-xs-offset-right-9 {
  margin-right: 75%;
}

.col-xs-offset-right-8 {
  margin-right: 66.66666667%;
}

.col-xs-offset-right-7 {
  margin-right: 58.33333333%;
}

.col-xs-offset-right-6 {
  margin-right: 50%;
}

.col-xs-offset-right-5 {
  margin-right: 41.66666667%;
}

.col-xs-offset-right-4 {
  margin-right: 33.33333333%;
}

.col-xs-offset-right-3 {
  margin-right: 25%;
}

.col-xs-offset-right-2 {
  margin-right: 16.66666667%;
}

.col-xs-offset-right-1 {
  margin-right: 8.33333333%;
}

.col-xs-offset-right-0 {
  margin-right: 0;
}

@media (min-width: 768px) {
  .col-sm-offset-right-12 {
    margin-right: 100%;
  }

  .col-sm-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-sm-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-sm-offset-right-9 {
    margin-right: 75%;
  }

  .col-sm-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-sm-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-sm-offset-right-6 {
    margin-right: 50%;
  }

  .col-sm-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-sm-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-sm-offset-right-3 {
    margin-right: 25%;
  }

  .col-sm-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-sm-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-sm-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 992px) {
  .col-md-offset-right-12 {
    margin-right: 100%;
  }

  .col-md-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-md-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-md-offset-right-9 {
    margin-right: 75%;
  }

  .col-md-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-md-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-md-offset-right-6 {
    margin-right: 50%;
  }

  .col-md-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-md-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-md-offset-right-3 {
    margin-right: 25%;
  }

  .col-md-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-md-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-md-offset-right-0 {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .col-lg-offset-right-12 {
    margin-right: 100%;
  }

  .col-lg-offset-right-11 {
    margin-right: 91.66666667%;
  }

  .col-lg-offset-right-10 {
    margin-right: 83.33333333%;
  }

  .col-lg-offset-right-9 {
    margin-right: 75%;
  }

  .col-lg-offset-right-8 {
    margin-right: 66.66666667%;
  }

  .col-lg-offset-right-7 {
    margin-right: 58.33333333%;
  }

  .col-lg-offset-right-6 {
    margin-right: 50%;
  }

  .col-lg-offset-right-5 {
    margin-right: 41.66666667%;
  }

  .col-lg-offset-right-4 {
    margin-right: 33.33333333%;
  }

  .col-lg-offset-right-3 {
    margin-right: 25%;
  }

  .col-lg-offset-right-2 {
    margin-right: 16.66666667%;
  }

  .col-lg-offset-right-1 {
    margin-right: 8.33333333%;
  }

  .col-lg-offset-right-0 {
    margin-right: 0;
  }
}

.pagination {
  margin: 0px;
}

li a.bg-red.filter {
  background: red;
}

li a.filter {
  background: #303030;
}

.tooltip-inner {
  white-space: pre-wrap;
}

span.label-structural {
  background-color: #e74c3c;
}

.part-orders-table span.label {
  display: inline-block;
}

td.local-project {
  border-left: 5px solid darkgreen;
}

.projects-listing tr.draft,
.panel-heading.draft {
  border-left: 5px solid #f39c12;
}

.projects-listing tr.ready_for_review,
.panel-heading.ready_for_review {
  border-left: 5px solid #3498db;
}

.projects-listing tr.new_construction_phase_1,
.panel-heading.new_construction_phase_1 {
  border-left: 5px solid #555555;
}

.projects-listing tr.new_construction_phase_2,
.panel-heading.new_construction_phase_2 {
  border-left: 5px solid #808080;
}

.projects-listing tr.new_construction_phase_3,
.panel-heading.new_construction_phase_3 {
  border-left: 5px solid #F2F2F2;
}

.projects-listing tr.purchasing_review,
.panel-heading.purchasing_review {
  border-left: 5px solid #e74c3c;
}

.projects-listing tr.ready_for_production,
.panel-heading.ready_for_production {
  border-left: 5px solid green;
}

.projects-listing tr.imported,
.panel-heading.imported {
  border-left: 5px solid #00bc8c;
}

.bs-component .label-draft {
  border-left: 5px solid #f39c12;
  border-radius: 0;
  background: none;
}

.bs-component .label-ready-for-review {
  border-left: 5px solid #3498db;
  border-radius: 0;
  background: none;
}

.bs-component .label-ready-for-production {
  border-left: 5px solid green;
  border-radius: 0;
  background: none;
}

.bs-component .label-purchasing-review {
  border-left: 5px solid #e74c3c;
  border-radius: 0;
  background: none;
}

.bs-component .label-new-construction-phase-1 {
  border-left: 5px solid #505050;
  border-radius: 0;
  background: none;
}

.bs-component .label-new-construction-phase-2 {
  border-left: 5px solid #808080;
  border-radius: 0;
  background: none;
}

.bs-component .label-new-construction-phase-3 {
  border-left: 5px solid #F2F2F2;
  border-radius: 0;
  background: none;
}

.bs-component .label-imported {
  border-left: 5px solid #00bc8c;
  border-radius: 0;
  background: none;
}

.p-status .draft {
  background-color: #f39c12;
  margin: 2px;
}

.p-status .ordered {
  background-color: green;
}

.p-status .canceled {
  background-color: #e74c3c;
}

.p-status .confirmed {
  background-color: #3498db;
}

.p-status .deposit {
  background-color: #808080;
}

.p-status .ready_for_review {
  background-color: #3498db;
}

.p-status .ready_for_production {
  background-color: green;
}

.p-status .purchasing_review {
  background-color: #e74c3c;
}

.p-status .new_construction_phase_1 {
  background-color: #e74c3c;
}

.p-status .new_construction_phase_2 {
  background-color: #808080;
}

.p-status .new_construction_phase_3 {
  background-color: #F2F2F2;
}

.p-status .imported {
  background-color: #00bc8c;
}


.bom-index.bom-index-draft {
  border-left: 6px solid #e74c3c;
  border-radius: 0;
  background: none;
}

.bom-index.bom-index-pending {
  border-left: 6px solid #f39c12;
  border-radius: 0;
  background: none;
}

.bom-index.bom-index-purchased {
  border-left: 6px solid #00bc8c;
  border-radius: 0;
  background: none;
}

.btn.btn-status-draft {
  color: #f39c12;
}

.btn.btn-status-ready-for-review {
  color: #375a7f;
}

.btn.btn-status-ready-for-production {
  color: green;
}

.btn.btn-status-imported {
  color: #00bc8c;
}

.btn.btn-status-purchasing-review {
  color: #e74c3c;
}

.project-search-form .search-input {
  padding: 0px 0px 0px 15px;
}

.list-group-item.opening-with-parts {
  color: #ffffff;
  background-color: #00bc8c
}

ul.opening-nav li {
  cursor: pointer;
}

.opening-nav .list-group-item a:hover {
  color: #ffffff;
  text-decoration: none;
  font-weight: bold;
}

.opening-nav .list-group-item:hover {
  background-color: #444;
}

.list-group-item.opening-with-parts:hover {
  background-color: #444;
}

.openingSelected.list-group-item,
.openingSelected.list-group-item.opening-without-parts,
.openingSelected.list-group-item.opening-with-parts {
  background-color: #375a7f;
  border-color: #375a7f;
}

.video-link, .panel-title {
  cursor: pointer;
}

.list-group-item > p{
    margin-bottom: 10px;
}

@keyframes blink {
  0% {
    background-color: rgba(128, 128, 128, 1)
  }
  50% {
    background-color: rgba(128, 128, 128, 0.5)
  }
  100% {
    background-color: rgba(128, 128, 128, 1)
  }
}

@-webkit-keyframes blink {
  0% {
    background-color: rgba(128, 128, 128, 1)
  }
  50% {
    background-color: rgba(128, 128, 128, 0.5)
  }
  100% {
    background-color: rgba(128, 128, 128, 1)
  }
}

//
//tr.freshPart {
//
//  -moz-transition: all 0.5s ease-in-out;
//
//  -webkit-transition: all 0.5s ease-in-out;
//
//  -o-transition: all 0.5s ease-in-out;
//
//  -ms-transition: all 0.5s ease-in-out;
//
//  transition: all 0.5s ease-in-out;
//
//  -moz-animation: blink normal 1.5s 5 ease-in-out;
//
//  -webkit-animation: blink normal 1.5s 5 ease-in-out;
//
//  -ms-animation: blink normal 1.5s 5 ease-in-out;
//
//  animation: blink normal 1.5s 5 ease-in-out;
//
//}

tr.freshPart {
  border-left: 5px solid #3498db;
}

#supplemental-part-order-table-modal input.qty,
#supplemental-part-order-table-modal input.length {
  width: 50px;
}

#supplemental-part-order-table-modal tr.has-success,
#structural-part-order-table-modal tr.has-success {
  border-left: 5px solid darkgreen;
}

#supplemental-part-order-table-modal tr.has-error,
#structural-part-order-table-modal tr.has-error {
  border-left: 5px solid #e74c3c;
}

.panel-heading.success {

  border-left: 10px solid darkgreen;
}

.panel-heading.warning {

  border-left: 10px solid #f39c12;
}

.product-faker span.badge-part-qty {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 13px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: grey;
  border-radius: 10px;
  min-width: 30px;
  margin-top: -5px;
}

fieldset {
  border: 2px solid #464545 !important;
  margin: 10px 0px;
  xmin-width: 0;
  padding: 10px;
  position: relative;
  border-radius: 4px;
  background: none;
  padding-left: 10px !important;
}

legend {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  width: 35%;
  border: 2px solid #464545;
  border-radius: 4px;
  padding: 0px 0px 0px 10px;
  background: none;
}

.ui-colorpicker-swatch {
  width: 25px !important;
  height: 25px !important;
}

.select-part-list li,
.select-part-list a {
  cursor: pointer;
}

.glowing-border {
  border: 2px solid #dadada !important;
  border-radius: 7px !important;
}

.glowing-border:focus {
  outline: none !important;
  border-color: #e74c3c !important;
  box-shadow: 0 0 10px #e74c3c !important;
}

#app {
  min-height: 828px;
}

#footer {
  height: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px 0px;
  background-color: #375a7f;
  text-align: center;
}

.input-group-btn.input-sm {
  margin: 0px;
  padding: 0px;
}

.input-group-btn.input-sm button {
  padding: 5px;
}

.cnt-controls-fixed {
  position: fixed !important;
  top: 10px !important;
  right: 10px !important;
  z-index: 100 !important;
}

.cnt-controls-fixed button {
  z-index: 100 !important;
}

#search-project-id {
  width: 120px;
  margin-top: 14px;
  margin-right: 50px
}

.disabled-click {
  pointer-events: none;
  background-color: #464545;
}

.active-option {
  text-decoration: none;
  color: #ffffff;
  background-color: #375a7f;
}

input[type="checkbox"].select-project-checkbox {
  margin: 13px;
  display: inline-block;
  float: left;
}

.dp-active {
  text-decoration: none;
  color: #ffffff;
  background-color: #375a7f;
}

@media (max-width: 999px) {
  .alert {
    margin-bottom: 40px;
  }
}

@media (max-width: 950px) {

  ul{
    //display: flex;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 2px
  }
  .menu li {
    display: inline;
    width: 100%;
    text-align: center;
  }
}

.open>.dropdown-menu {
  max-height:200px !important;
  overflow-y: auto !important;
}


.b-standardized input {
  text-transform: uppercase;
}
.b-standardized li {
  text-transform: uppercase;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 999;
}